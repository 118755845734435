import { RouteInfo } from './vertical-menu.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'navigation.dashboard',
    icon: 'ft-home',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: []
  },
  {
    path: '/services',
    title: 'navigation.services',
    icon: 'ft-file-text',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: [],
  },
  {
    path: '/services-logs',
    title: 'navigation.logs',
    icon: 'ft-database',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: [],
  },
  {
    path: '/users',
    title: 'navigation.users',
    icon: 'ft-users',
    class: 'dropdown nav-item',
    isExternalLink: false,
    submenu: [],
    roles: [],
  },
];
